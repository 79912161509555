import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalEvents } from "src/constants/globalEvents";
import reportErrorSentry from "src/lib/reportErrorSentry";
import AppointmentsBloc from "src/state/AppointmentsBloc/AppointmentsBloc";
import InDialog from "src/ui/components/InDialog/InDialog";
import Loader from "src/ui/components/Loader/Loader";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import {
  ErrorPageLayout,
  CenterEl
} from "src/ui/components/SentryBoundary/ErrorPage";
import translate from "src/lib/translate";
import Translate from "../../Translate/Translate";

const DialogIframe = styled.iframe`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  padding-bottom: 0 !important;
`;

const RescheduleAppointmentDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get("id") ?? "";
  const iframeUrlParam = searchParams.get("url") ?? "";

  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);
  const [iframeUrl, setIframeUrl] = useState<string>(iframeUrlParam);
  const [error, setError] = useState<boolean>();

  const onClose = (dispatchEvent = true) => {
    if (dispatchEvent) {
      document.dispatchEvent(
        new CustomEvent(globalEvents.APPOINTMENT_RESCHEDULED, {
          bubbles: true,
          composed: true
        })
      );
    }

    void modalRef?.current?.dismiss();

    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  useEffect(() => {
    if (iframeUrl) {
      return;
    }
    void AppointmentsBloc.getAppointmentReschedulingUrl(appointmentId)
      .then((url) => {
        setIframeUrl(url);
      })
      .catch((e: unknown) => {
        reportErrorSentry(e);
        setError(true);
      });
  }, []);

  return (
    <InDialog
      width="min(calc(100% - 4rem), 120rem)"
      height="min(calc(100% - 4rem), 70rem)"
      simple
      backdropDismiss={false}
      title={translate("appointment.popup.title", {
        context: "reschedule"
      })}
      onClose={onClose}
      setRef={setModalRef}
      returnUrl={returnUrl}
    >
      {error && (
        <ErrorPageLayout>
          <CenterEl>
            <nine-heading>
              <nine-spacer s="xs"></nine-spacer>
              <h4 className="m0 color-c-80">
                <Translate msg="errorPage.reschedule" />{" "}
              </h4>
              <nine-spacer s="md"></nine-spacer>
              <p className="m0 color-c-80">
                <Translate msg="help.contactUsGeneric" />
                <nine-spacer s="xs"></nine-spacer>
                <Translate msg="help.thankYouMessage" />
              </p>
            </nine-heading>
            <nine-spacer s="lg"></nine-spacer>
            <nine-button
              onClick={(): void => {
                navigate("/app/chat");
              }}
            >
              <Translate msg="chatWithCareTeam" />
            </nine-button>
          </CenterEl>
        </ErrorPageLayout>
      )}
      {iframeUrl && !error && (
        <DialogIframe src={iframeUrl} id="schedule-appointment" />
      )}
      {!iframeUrl && !error && <Loader active fixed />}
    </InDialog>
  );
};

export default RescheduleAppointmentDialog;
